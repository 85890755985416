import { ILanugage } from '../../types/translatable';

export const languages: ILanugage[] = [
  { label: 'Afrikaans', countryCode: 'ZA', isoCode: 'af' },
  { label: 'Albanian', countryCode: 'AL', isoCode: 'sq' },
  { label: 'Amharic', countryCode: 'ET', isoCode: 'am' },
  { label: 'Arabic', countryCode: 'SA', isoCode: 'ar' },
  { label: 'Arabic (Algeria)', countryCode: 'DZ', isoCode: 'ar_DZ' },
  { label: 'Arabic (Bahrain)', countryCode: 'BH', isoCode: 'ar_BH' },
  { label: 'Arabic (Egypt)', countryCode: 'EG', isoCode: 'ar_EG' },
  { label: 'Arabic (Iraq)', countryCode: 'IQ', isoCode: 'ar_IQ' },
  { label: 'Arabic (Jordan)', countryCode: 'JO', isoCode: 'ar_JO' },
  { label: 'Arabic (Kuwait)', countryCode: 'KW', isoCode: 'ar_KW' },
  { label: 'Arabic (Lebanon)', countryCode: 'LB', isoCode: 'ar_LB' },
  { label: 'Arabic (Libya)', countryCode: 'LY', isoCode: 'ar_LY' },
  { label: 'Arabic (Morocco)', countryCode: 'MA', isoCode: 'ar_MA' },
  { label: 'Arabic (Oman)', countryCode: 'OM', isoCode: 'ar_OM' },
  { label: 'Arabic (Qatar)', countryCode: 'QA', isoCode: 'ar_QA' },
  { label: 'Arabic (Saudi Arabia)', countryCode: 'SA', isoCode: 'ar_SA' },
  { label: 'Arabic (Sudan)', countryCode: 'SD', isoCode: 'ar_SD' },
  { label: 'Arabic (Syria)', countryCode: 'SY', isoCode: 'ar_SY' },
  { label: 'Arabic (Tunisia)', countryCode: 'TN', isoCode: 'ar_TN' },
  { label: 'Arabic (United Arab Emirates)', countryCode: 'AE', isoCode: 'ar_AE' },
  { label: 'Arabic (Yemen)', countryCode: 'YE', isoCode: 'ar_YE' },
  { label: 'Armenian', countryCode: 'AM', isoCode: 'hy' },
  { label: 'Basque', countryCode: 'ES', isoCode: 'eu' },
  { label: 'Bengali', countryCode: 'BD', isoCode: 'bn' },
  { label: 'Bosnian', countryCode: 'BA', isoCode: 'bs' },
  { label: 'Bulgarian', countryCode: 'BG', isoCode: 'bg' },
  { label: 'Burmese', countryCode: 'MM', isoCode: 'my' },
  { label: 'Catalan', countryCode: 'ES', isoCode: 'ca' },
  { label: 'Chinese (Hong Kong)', countryCode: 'HK', isoCode: 'zh_HK' },
  { label: 'Chinese (Malaysia)', countryCode: 'MY', isoCode: 'zh_MY' },
  { label: 'Chinese (Simplified)', countryCode: 'CN', isoCode: 'zh_CN' },
  { label: 'Chinese (Singapore)', countryCode: 'SG', isoCode: 'zh_SG' },
  { label: 'Chinese (Traditional)', countryCode: 'TW', isoCode: 'zh_TW' },
  { label: 'Chuj', countryCode: 'GT', isoCode: 'cac' },
  { label: 'Croatian', countryCode: 'HR', isoCode: 'hr' },
  { label: 'Czech', countryCode: 'CZ', isoCode: 'cs' },
  { label: 'Danish', countryCode: 'DK', isoCode: 'da' },
  { label: 'Dutch', countryCode: 'NL', isoCode: 'nl_NL' },
  { label: 'Dutch (Belgium)', countryCode: 'BE', isoCode: 'nl_BE' },
  { label: 'English', countryCode: 'US', isoCode: 'en_US' },
  { label: 'English (Albania)', countryCode: 'AL', isoCode: 'en_AL' },
  { label: 'English (Andorra)', countryCode: 'AD', isoCode: 'en_AD' },
  { label: 'English (Australia)', countryCode: 'AU', isoCode: 'en_AU' },
  { label: 'English (Austria)', countryCode: 'AT', isoCode: 'en_AT' },
  { label: 'English (Belgium)', countryCode: 'BE', isoCode: 'en_BE' },
  { label: 'English (Bosnia & Herzegovina)', countryCode: 'BA', isoCode: 'en_BA' },
  { label: 'English (Bulgaria)', countryCode: 'BG', isoCode: 'en_BG' },
  { label: 'English (Canada)', countryCode: 'CA', isoCode: 'en_CA' },
  { label: 'English (Croatia)', countryCode: 'HR', isoCode: 'en_HR' },
  { label: 'English (Cyprus)', countryCode: 'CY', isoCode: 'en_CY' },
  { label: 'English (Czechia)', countryCode: 'CZ', isoCode: 'en_CZ' },
  { label: 'English (Denmark)', countryCode: 'DK', isoCode: 'en_DK' },
  { label: 'English (Dominica)', countryCode: 'DM', isoCode: 'en_DM' },
  { label: 'English (Estonia)', countryCode: 'EE', isoCode: 'en_EE' },
  { label: 'English (Finland)', countryCode: 'FI', isoCode: 'en_FI' },
  { label: 'English (France)', countryCode: 'FR', isoCode: 'en_FR' },
  { label: 'English (Germany)', countryCode: 'DE', isoCode: 'en_DE' },
  { label: 'English (Greece)', countryCode: 'GR', isoCode: 'en_GR' },
  { label: 'English (Grenada)', countryCode: 'GD', isoCode: 'en_GD' },
  { label: 'English (Hong Kong)', countryCode: 'HK', isoCode: 'en_HK' },
  { label: 'English (Hungary)', countryCode: 'HU', isoCode: 'en_HU' },
  { label: 'English (Iceland)', countryCode: 'IS', isoCode: 'en_IS' },
  { label: 'English (India)', countryCode: 'IN', isoCode: 'en_IN' },
  { label: 'English (Ireland)', countryCode: 'IE', isoCode: 'en_IE' },
  { label: 'English (Israel)', countryCode: 'IL', isoCode: 'en_IL' },
  { label: 'English (Italy)', countryCode: 'IT', isoCode: 'en_IT' },
  { label: 'English (Japan)', countryCode: 'JP', isoCode: 'en_JP' },
  { label: 'English (Latvia)', countryCode: 'LV', isoCode: 'en_LV' },
  { label: 'English (Liechtenstein)', countryCode: 'LI', isoCode: 'en_LI' },
  { label: 'English (Lithuania)', countryCode: 'LT', isoCode: 'en_LT' },
  { label: 'English (Luxembourg)', countryCode: 'LU', isoCode: 'en_LU' },
  { label: 'English (Malaysia)', countryCode: 'MY', isoCode: 'en_MY' },
  { label: 'English (Malta)', countryCode: 'MT', isoCode: 'en_MT' },
  { label: 'English (Monaco)', countryCode: 'MC', isoCode: 'en_MC' },
  { label: 'English (Montenegro)', countryCode: 'ME', isoCode: 'en_ME' },
  { label: 'English (Netherlands)', countryCode: 'NL', isoCode: 'en_NL' },
  { label: 'English (New Zealand)', countryCode: 'NZ', isoCode: 'en_NZ' },
  { label: 'English (North Macedonia)', countryCode: 'MK', isoCode: 'en_MK' },
  { label: 'English (Norway)', countryCode: 'NO', isoCode: 'en_NO' },
  { label: 'English (Philippines)', countryCode: 'PH', isoCode: 'en_PH' },
  { label: 'English (Poland)', countryCode: 'PL', isoCode: 'en_PL' },
  { label: 'English (Portugal)', countryCode: 'PT', isoCode: 'en_PT' },
  { label: 'English (Romania)', countryCode: 'RO', isoCode: 'en_RO' },
  { label: 'English (Serbia)', countryCode: 'RS', isoCode: 'en_RS' },
  { label: 'English (Singapore)', countryCode: 'SG', isoCode: 'en_SG' },
  { label: 'English (Slovakia)', countryCode: 'SK', isoCode: 'en_SK' },
  { label: 'English (Slovenia)', countryCode: 'SI', isoCode: 'en_SI' },
  { label: 'English (South Africa)', countryCode: 'ZA', isoCode: 'en_ZA' },
  { label: 'English (South Korea)', countryCode: 'KR', isoCode: 'en_KR' },
  { label: 'English (Spain)', countryCode: 'ES', isoCode: 'en_ES' },
  { label: 'English (St. Kitts & Nevis)', countryCode: 'KN', isoCode: 'en_KN' },
  { label: 'English (St. Lucia)', countryCode: 'LC', isoCode: 'en_LC' },
  { label: 'English (St. Vincent & Grenadines)', countryCode: 'VC', isoCode: 'en_VC' },
  { label: 'English (Sweden)', countryCode: 'SE', isoCode: 'en_SE' },
  { label: 'English (Switzerland)', countryCode: 'CH', isoCode: 'en_CH' },
  { label: 'English (Taiwan)', countryCode: 'TW', isoCode: 'en_TW' },
  { label: 'English (Thailand)', countryCode: 'TH', isoCode: 'en_TH' },
  { label: 'English (Turkey)', countryCode: 'TR', isoCode: 'en_TR' },
  { label: 'English (UK)', countryCode: 'GB', isoCode: 'en_GB' },
  { label: 'English (United Arab Emirates)', countryCode: 'AE', isoCode: 'en_AE' },
  { label: 'Estonian', countryCode: 'EE', isoCode: 'et' },
  { label: 'Farsi', countryCode: 'IR', isoCode: 'fa' },
  { label: 'Finnish', countryCode: 'FI', isoCode: 'fi' },
  { label: 'French', countryCode: 'FR', isoCode: 'fr' },
  { label: 'French (Belgium)', countryCode: 'BE', isoCode: 'fr_BE' },
  { label: 'French (Canada)', countryCode: 'CA', isoCode: 'fr_CA' },
  { label: 'French (Luxembourg)', countryCode: 'LU', isoCode: 'fr_LU' },
  { label: 'French (Morocco)', countryCode: 'MA', isoCode: 'fr_MA' },
  { label: 'French (Switzerland)', countryCode: 'CH', isoCode: 'fr_CH' },
  { label: 'Georgian', countryCode: 'GE', isoCode: 'ka' },
  { label: 'German', countryCode: 'DE', isoCode: 'de' },
  { label: 'German (Austria)', countryCode: 'AT', isoCode: 'de_AT' },
  { label: 'German (Belgium)', countryCode: 'BE', isoCode: 'de_BE' },
  { label: 'German (Liechtenstein)', countryCode: 'LI', isoCode: 'de_LI' },
  { label: 'German (Luxembourg)', countryCode: 'LU', isoCode: 'de_LU' },
  { label: 'German (Switzerland)', countryCode: 'CH', isoCode: 'de_CH' },
  { label: 'Greek', countryCode: 'GR', isoCode: 'el' },
  { label: 'Greek (Cyprus)', countryCode: 'CY', isoCode: 'el_CY' },
  { label: 'Greenlandic', countryCode: 'GL', isoCode: 'kl' },
  { label: 'Gujarati', countryCode: 'IN', isoCode: 'gu' },
  { label: 'Haitian Creole', countryCode: 'HT', isoCode: 'ht' },
  { label: 'Hawaiian', countryCode: 'US', isoCode: 'haw' },
  { label: 'Hebrew', countryCode: 'IL', isoCode: 'iw' },
  { label: 'Hindi', countryCode: 'IN', isoCode: 'hi' },
  { label: 'Hmong', countryCode: 'CN', isoCode: 'hmn' },
  { label: 'Hungarian', countryCode: 'HU', isoCode: 'hu' },
  { label: 'Icelandic', countryCode: 'IS', isoCode: 'is' },
  { label: 'Indonesian', countryCode: 'ID', isoCode: 'in' },
  { label: 'Irish', countryCode: 'IE', isoCode: 'ga' },
  { label: 'Italian', countryCode: 'IT', isoCode: 'it' },
  { label: 'Italian (Switzerland)', countryCode: 'CH', isoCode: 'it_CH' },
  { label: 'Japanese', countryCode: 'JP', isoCode: 'ja' },
  { label: 'Kaqchikel', countryCode: 'GT', isoCode: 'cak' },
  { label: 'Kannada', countryCode: 'IN', isoCode: 'kn' },
  { label: 'Kazakh', countryCode: 'KZ', isoCode: 'kk' },
  { label: 'Khmer', countryCode: 'KH', isoCode: 'km' },
  { label: 'Kiche', countryCode: 'GT', isoCode: 'quc' },
  { label: 'Korean', countryCode: 'KR', isoCode: 'ko' },
  { label: 'Latvian', countryCode: 'LV', isoCode: 'lv' },
  { label: 'Lithuanian', countryCode: 'LT', isoCode: 'lt' },
  { label: 'Luxembourgish', countryCode: 'LU', isoCode: 'lb' },
  { label: 'Macedonian', countryCode: 'MK', isoCode: 'mk' },
  { label: 'Malay', countryCode: 'MY', isoCode: 'ms' },
  { label: 'Malayalam', countryCode: 'IN', isoCode: 'ml' },
  { label: 'Maltese', countryCode: 'MT', isoCode: 'mt' },
  { label: 'Marathi', countryCode: 'IN', isoCode: 'mr' },
  { label: 'Montenegrin', countryCode: 'ME', isoCode: 'sh_ME' },
  { label: 'Norwegian', countryCode: 'NO', isoCode: 'no' },
  { label: 'Polish', countryCode: 'PL', isoCode: 'pl' },
  { label: 'Portuguese (Brazil)', countryCode: 'BR', isoCode: 'pt_BR' },
  { label: 'Portuguese (European)', countryCode: 'PT', isoCode: 'pt_PT' },
  { label: 'Punjabi', countryCode: 'IN', isoCode: 'pa' },
  { label: 'Romanian', countryCode: 'RO', isoCode: 'ro' },
  { label: 'Romanian (Moldova)', countryCode: 'MD', isoCode: 'ro_MD' },
  { label: 'Romansh', countryCode: 'CH', isoCode: 'rm' },
  { label: 'Russian', countryCode: 'RU', isoCode: 'ru' },
  { label: 'Russian (Armenia)', countryCode: 'AM', isoCode: 'ru_AM' },
  { label: 'Russian (Belarus)', countryCode: 'BY', isoCode: 'ru_BY' },
  { label: 'Russian (Kazakhstan)', countryCode: 'KZ', isoCode: 'ru_KZ' },
  { label: 'Russian (Kyrgyzstan)', countryCode: 'KG', isoCode: 'ru_KG' },
  { label: 'Russian (Lithuania)', countryCode: 'LT', isoCode: 'ru_LT' },
  { label: 'Russian (Moldova)', countryCode: 'MD', isoCode: 'ru_MD' },
  { label: 'Russian (Poland)', countryCode: 'PL', isoCode: 'ru_PL' },
  { label: 'Russian (Ukraine)', countryCode: 'UA', isoCode: 'ru_UA' },
  { label: 'Samoan', countryCode: 'WS', isoCode: 'sm' },
  { label: 'Serbian (Cyrillic)', countryCode: 'RS', isoCode: 'sr' },
  { label: 'Serbian (Latin)', countryCode: 'RS', isoCode: 'sh' },
  { label: 'Slovak', countryCode: 'SK', isoCode: 'sk' },
  { label: 'Slovenian', countryCode: 'SI', isoCode: 'sl' },
  { label: 'Spanish', countryCode: 'ES', isoCode: 'es' },
  { label: 'Spanish (Andorra)', countryCode: 'AD', isoCode: 'es_AD' },
  { label: 'Spanish (Argentina)', countryCode: 'AR', isoCode: 'es_AR' },
  { label: 'Spanish (Bolivia)', countryCode: 'BO', isoCode: 'es_BO' },
  { label: 'Spanish (Chile)', countryCode: 'CL', isoCode: 'es_CL' },
  { label: 'Spanish (Colombia)', countryCode: 'CO', isoCode: 'es_CO' },
  { label: 'Spanish (Costa Rica)', countryCode: 'CR', isoCode: 'es_CR' },
  { label: 'Spanish (Dominican Republic)', countryCode: 'DO', isoCode: 'es_DO' },
  { label: 'Spanish (Ecuador)', countryCode: 'EC', isoCode: 'es_EC' },
  { label: 'Spanish (El Salvador)', countryCode: 'SV', isoCode: 'es_SV' },
  { label: 'Spanish (Guatemala)', countryCode: 'GT', isoCode: 'es_GT' },
  { label: 'Spanish (Honduras)', countryCode: 'HN', isoCode: 'es_HN' },
  { label: 'Spanish (Mexico)', countryCode: 'MX', isoCode: 'es_MX' },
  { label: 'Spanish (Nicaragua)', countryCode: 'NI', isoCode: 'es_NI' },
  { label: 'Spanish (Panama)', countryCode: 'PA', isoCode: 'es_PA' },
  { label: 'Spanish (Paraguay)', countryCode: 'PY', isoCode: 'es_PY' },
  { label: 'Spanish (Peru)', countryCode: 'PE', isoCode: 'es_PE' },
  { label: 'Spanish (Puerto Rico)', countryCode: 'PR', isoCode: 'es_PR' },
  { label: 'Spanish (United States)', countryCode: 'US', isoCode: 'es_US' },
  { label: 'Spanish (Uruguay)', countryCode: 'UY', isoCode: 'es_UY' },
  { label: 'Spanish (Venezuela)', countryCode: 'VE', isoCode: 'es_VE' },
  { label: 'Svenska (Finland)', countryCode: 'FI', isoCode: 'sv_FI' },
  { label: 'Swahili', countryCode: 'TZ', isoCode: 'sw' },
  { label: 'Swedish', countryCode: 'SE', isoCode: 'sv' },
  { label: 'Tagalog', countryCode: 'PH', isoCode: 'tl' },
  { label: 'Tamil', countryCode: 'IN', isoCode: 'ta' },
  { label: 'Te reo', countryCode: 'NZ', isoCode: 'mi' },
  { label: 'Telugu', countryCode: 'IN', isoCode: 'te' },
  { label: 'Thai', countryCode: 'TH', isoCode: 'th' },
  { label: 'Turkish', countryCode: 'TR', isoCode: 'tr' },
  { label: 'Ukrainian', countryCode: 'UA', isoCode: 'uk' },
  { label: 'Urdu', countryCode: 'PK', isoCode: 'ur' },
  { label: 'Vietnamese', countryCode: 'VN', isoCode: 'vi' },
  { label: 'Welsh', countryCode: 'GB', isoCode: 'cy' },
  { label: 'Xhosa', countryCode: 'ZA', isoCode: 'xh' },
  { label: 'Yiddish', countryCode: 'IL', isoCode: 'ji' },
  { label: 'Zulu', countryCode: 'ZA', isoCode: 'zu' },
];

export const deepLLanguages: ILanugage[] = [
  { label: 'Arabic', countryCode: 'SA', isoCode: 'ar' },
  { label: 'Bulgarian', countryCode: 'BG', isoCode: 'bg' },
  { label: 'Czech', countryCode: 'CZ', isoCode: 'cs' },
  { label: 'Danish', countryCode: 'DK', isoCode: 'da' },
  { label: 'German', countryCode: 'DE', isoCode: 'de' },
  { label: 'Greek', countryCode: 'GR', isoCode: 'el' },
  { label: 'English', countryCode: 'US', isoCode: 'en' },
  { label: 'English (British)', countryCode: 'GB', isoCode: 'en-GB' },
  { label: 'English (American)', countryCode: 'US', isoCode: 'en-US' },
  { label: 'Spanish', countryCode: 'ES', isoCode: 'es' },
  { label: 'Estonian', countryCode: 'EE', isoCode: 'et' },
  { label: 'Finnish', countryCode: 'FI', isoCode: 'fi' },
  { label: 'French', countryCode: 'FR', isoCode: 'fr' },
  { label: 'Hungarian', countryCode: 'HU', isoCode: 'hu' },
  { label: 'Indonesian', countryCode: 'ID', isoCode: 'id' },
  { label: 'Italian', countryCode: 'IT', isoCode: 'it' },
  { label: 'Japanese', countryCode: 'JP', isoCode: 'ja' },
  { label: 'Korean', countryCode: 'KR', isoCode: 'ko' },
  { label: 'Lithuanian', countryCode: 'LT', isoCode: 'lt' },
  { label: 'Latvian', countryCode: 'LV', isoCode: 'lv' },
  { label: 'Norwegian Bokmål', countryCode: 'NO', isoCode: 'nb' },
  { label: 'Dutch', countryCode: 'NL', isoCode: 'nl' },
  { label: 'Polish', countryCode: 'PL', isoCode: 'pl' },
  { label: 'Portuguese', countryCode: 'PT', isoCode: 'pt' },
  { label: 'Portuguese (Brazil)', countryCode: 'BR', isoCode: 'pt-BR' },
  { label: 'Portuguese (Portugal)', countryCode: 'PT', isoCode: 'pt-PT' },
  { label: 'Romanian', countryCode: 'RO', isoCode: 'ro' },
  { label: 'Russian', countryCode: 'RU', isoCode: 'ru' },
  { label: 'Slovak', countryCode: 'SK', isoCode: 'sk' },
  { label: 'Slovenian', countryCode: 'SI', isoCode: 'sl' },
  { label: 'Swedish', countryCode: 'SE', isoCode: 'sv' },
  { label: 'Turkish', countryCode: 'TR', isoCode: 'tr' },
  { label: 'Ukrainian', countryCode: 'UA', isoCode: 'uk' },
  { label: 'Chinese', countryCode: 'CN', isoCode: 'zh' },
  { label: 'Chinese (Simplified)', countryCode: 'CN', isoCode: 'zh-HANS' },
  { label: 'Chinese (Traditional)', countryCode: 'TW', isoCode: 'zh-HANT' }
];

export const googleTranslateLanguages: ILanugage[] = [
  { label: 'Abkhaz', isoCode: 'ab', countryCode: 'GE' },
  { label: 'Acehnese', isoCode: 'ace', countryCode: 'ID' },
  { label: 'Acholi', isoCode: 'ach', countryCode: 'UG' },
  { label: 'Afrikaans', isoCode: 'af', countryCode: 'ZA' },
  { label: 'Albanian', isoCode: 'sq', countryCode: 'AL' },
  { label: 'Alur', isoCode: 'alz', countryCode: 'UG' },
  { label: 'Amharic', isoCode: 'am', countryCode: 'ET' },
  { label: 'Arabic', isoCode: 'ar', countryCode: 'SA' },
  { label: 'Armenian', isoCode: 'hy', countryCode: 'AM' },
  { label: 'Assamese', isoCode: 'as', countryCode: 'IN' },
  { label: 'Awadhi', isoCode: 'awa', countryCode: 'IN' },
  { label: 'Aymara', isoCode: 'ay', countryCode: 'BO' },
  { label: 'Azerbaijani', isoCode: 'az', countryCode: 'AZ' },
  { label: 'Balinese', isoCode: 'ban', countryCode: 'ID' },
  { label: 'Bambara', isoCode: 'bm', countryCode: 'ML' },
  { label: 'Bashkir', isoCode: 'ba', countryCode: 'RU' },
  { label: 'Basque', isoCode: 'eu', countryCode: 'ES' },
  { label: 'Batak Karo', isoCode: 'btx', countryCode: 'ID' },
  { label: 'Batak Simalungun', isoCode: 'bts', countryCode: 'ID' },
  { label: 'Batak Toba', isoCode: 'bbc', countryCode: 'ID' },
  { label: 'Belarusian', isoCode: 'be', countryCode: 'BY' },
  { label: 'Bemba', isoCode: 'bem', countryCode: 'ZM' },
  { label: 'Bengali', isoCode: 'bn', countryCode: 'BD' },
  { label: 'Betawi', isoCode: 'bew', countryCode: 'ID' },
  { label: 'Bhojpuri', isoCode: 'bho', countryCode: 'IN' },
  { label: 'Bikol', isoCode: 'bik', countryCode: 'PH' },
  { label: 'Bosnian', isoCode: 'bs', countryCode: 'BA' },
  { label: 'Breton', isoCode: 'br', countryCode: 'FR' },
  { label: 'Bulgarian', isoCode: 'bg', countryCode: 'BG' },
  { label: 'Buryat', isoCode: 'bua', countryCode: 'RU' },
  { label: 'Cantonese', isoCode: 'yue', countryCode: 'HK' },
  { label: 'Catalan', isoCode: 'ca', countryCode: 'ES' },
  { label: 'Cebuano', isoCode: 'ceb', countryCode: 'PH' },
  { label: 'Chinese (Simplified)', isoCode: 'zh-CN', countryCode: 'CN' },
  { label: 'Chinese (Traditional)', isoCode: 'zh-TW', countryCode: 'TW' },
  { label: 'Chuvash', isoCode: 'cv', countryCode: 'RU' },
  { label: 'Corsican', isoCode: 'co', countryCode: 'FR' },
  { label: 'Crimean Tatar', isoCode: 'crh', countryCode: 'UA' },
  { label: 'Croatian', isoCode: 'hr', countryCode: 'HR' },
  { label: 'Czech', isoCode: 'cs', countryCode: 'CZ' },
  { label: 'Danish', isoCode: 'da', countryCode: 'DK' },
  { label: 'Dinka', isoCode: 'din', countryCode: 'SS' },
  { label: 'Divehi', isoCode: 'dv', countryCode: 'MV' },
  { label: 'Dogri', isoCode: 'doi', countryCode: 'IN' },
  { label: 'Dombe', isoCode: 'dov', countryCode: 'MZ' },
  { label: 'Dutch', isoCode: 'nl', countryCode: 'NL' },
  { label: 'Dzongkha', isoCode: 'dz', countryCode: 'BT' },
  { label: 'English', isoCode: 'en', countryCode: 'US' },
  { label: 'Esperanto', isoCode: 'eo', countryCode: 'UN' },
  { label: 'Estonian', isoCode: 'et', countryCode: 'EE' },
  { label: 'Ewe', isoCode: 'ee', countryCode: 'GH' },
  { label: 'Fijian', isoCode: 'fj', countryCode: 'FJ' },
  { label: 'Filipino (Tagalog)', isoCode: 'fil', countryCode: 'PH' },
  { label: 'Finnish', isoCode: 'fi', countryCode: 'FI' },
  { label: 'French', isoCode: 'fr', countryCode: 'FR' },
  { label: 'French (Canadian)', isoCode: 'fr-CA', countryCode: 'CA' },
  { label: 'French (French)', isoCode: 'fr-FR', countryCode: 'FR' },
  { label: 'Frisian', isoCode: 'fy', countryCode: 'NL' },
  { label: 'Fulfulde', isoCode: 'ff', countryCode: 'SN' },
  { label: 'Ga', isoCode: 'gaa', countryCode: 'GH' },
  { label: 'Galician', isoCode: 'gl', countryCode: 'ES' },
  { label: 'Georgian', isoCode: 'ka', countryCode: 'GE' },
  { label: 'German', isoCode: 'de', countryCode: 'DE' },
  { label: 'Greek', isoCode: 'el', countryCode: 'GR' },
  { label: 'Guarani', isoCode: 'gn', countryCode: 'PY' },
  { label: 'Gujarati', isoCode: 'gu', countryCode: 'IN' },
  { label: 'Haitian Creole', isoCode: 'ht', countryCode: 'HT' },
  { label: 'Hakha Chin', isoCode: 'cnh', countryCode: 'MM' },
  { label: 'Hausa', isoCode: 'ha', countryCode: 'NG' },
  { label: 'Hawaiian', isoCode: 'haw', countryCode: 'US' },
  { label: 'Hebrew', isoCode: 'he', countryCode: 'IL' },
  { label: 'Hiligaynon', isoCode: 'hil', countryCode: 'PH' },
  { label: 'Hindi', isoCode: 'hi', countryCode: 'IN' },
  { label: 'Hmong', isoCode: 'hmn', countryCode: 'CN' },
  { label: 'Hungarian', isoCode: 'hu', countryCode: 'HU' },
  { label: 'Hunsrik', isoCode: 'hrx', countryCode: 'BR' },
  { label: 'Icelandic', isoCode: 'is', countryCode: 'IS' },
  { label: 'Igbo', isoCode: 'ig', countryCode: 'NG' },
  { label: 'Ilocano', isoCode: 'ilo', countryCode: 'PH' },
  { label: 'Indonesian', isoCode: 'id', countryCode: 'ID' },
  { label: 'Irish', isoCode: 'ga', countryCode: 'IE' },
  { label: 'Italian', isoCode: 'it', countryCode: 'IT' },
  { label: 'Japanese', isoCode: 'ja', countryCode: 'JP' },
  { label: 'Javanese', isoCode: 'jv', countryCode: 'ID' },
  { label: 'Kannada', isoCode: 'kn', countryCode: 'IN' },
  { label: 'Kapampangan', isoCode: 'pam', countryCode: 'PH' },
  { label: 'Kazakh', isoCode: 'kk', countryCode: 'KZ' },
  { label: 'Khmer', isoCode: 'km', countryCode: 'KH' },
  { label: 'Kiga', isoCode: 'cgg', countryCode: 'UG' },
  { label: 'Kinyarwanda', isoCode: 'rw', countryCode: 'RW' },
  { label: 'Kituba', isoCode: 'ktu', countryCode: 'CG' },
  { label: 'Konkani', isoCode: 'gom', countryCode: 'IN' },
  { label: 'Korean', isoCode: 'ko', countryCode: 'KR' },
  { label: 'Krio', isoCode: 'kri', countryCode: 'SL' },
  { label: 'Kurdish', isoCode: 'ku', countryCode: 'IQ' },
  { label: 'Kurdish (Sorani)', isoCode: 'ckb', countryCode: 'IQ' },
  { label: 'Kyrgyz', isoCode: 'ky', countryCode: 'KG' },
  { label: 'Lao', isoCode: 'lo', countryCode: 'LA' },
  { label: 'Latgalian', isoCode: 'ltg', countryCode: 'LV' },
  { label: 'Latin', isoCode: 'la', countryCode: 'VA' },
  { label: 'Latvian', isoCode: 'lv', countryCode: 'LV' },
  { label: 'Ligurian', isoCode: 'lij', countryCode: 'IT' },
  { label: 'Limburgan', isoCode: 'li', countryCode: 'NL' },
  { label: 'Lingala', isoCode: 'ln', countryCode: 'CD' },
  { label: 'Lithuanian', isoCode: 'lt', countryCode: 'LT' },
  { label: 'Lombard', isoCode: 'lmo', countryCode: 'IT' },
  { label: 'Luganda', isoCode: 'lg', countryCode: 'UG' },
  { label: 'Luo', isoCode: 'luo', countryCode: 'KE' },
  { label: 'Luxembourgish', isoCode: 'lb', countryCode: 'LU' },
  { label: 'Macedonian', isoCode: 'mk', countryCode: 'MK' },
  { label: 'Maithili', isoCode: 'mai', countryCode: 'IN' },
  { label: 'Makassar', isoCode: 'mak', countryCode: 'ID' },
  { label: 'Malagasy', isoCode: 'mg', countryCode: 'MG' },
  { label: 'Malay', isoCode: 'ms', countryCode: 'MY' },
  { label: 'Malay (Jawi)', isoCode: 'ms-Arab', countryCode: 'MY' },
  { label: 'Malayalam', isoCode: 'ml', countryCode: 'IN' },
  { label: 'Maltese', isoCode: 'mt', countryCode: 'MT' },
  { label: 'Maori', isoCode: 'mi', countryCode: 'NZ' },
  { label: 'Marathi', isoCode: 'mr', countryCode: 'IN' },
  { label: 'Meadow Mari', isoCode: 'chm', countryCode: 'RU' },
  { label: 'Meiteilon (Manipuri)', isoCode: 'mni-Mtei', countryCode: 'IN' },
  { label: 'Minang', isoCode: 'min', countryCode: 'ID' },
  { label: 'Mizo', isoCode: 'lus', countryCode: 'IN' },
  { label: 'Mongolian', isoCode: 'mn', countryCode: 'MN' },
  { label: 'Myanmar (Burmese)', isoCode: 'my', countryCode: 'MM' },
  { label: 'Ndebele (South)', isoCode: 'nr', countryCode: 'ZA' },
  { label: 'Nepalbhasa (Newari)', isoCode: 'new', countryCode: 'NP' },
  { label: 'Nepali', isoCode: 'ne', countryCode: 'NP' },
  { label: 'Norwegian', isoCode: 'no', countryCode: 'NO' },
  { label: 'Nuer', isoCode: 'nus', countryCode: 'SS' },
  { label: 'Nyanja (Chichewa)', isoCode: 'ny', countryCode: 'MW' },
  { label: 'Occitan', isoCode: 'oc', countryCode: 'FR' },
  { label: 'Odia (Oriya)', isoCode: 'or', countryCode: 'IN' },
  { label: 'Oromo', isoCode: 'om', countryCode: 'ET' },
  { label: 'Pangasinan', isoCode: 'pag', countryCode: 'PH' },
  { label: 'Papiamento', isoCode: 'pap', countryCode: 'CW' },
  { label: 'Pashto', isoCode: 'ps', countryCode: 'AF' },
  { label: 'Persian', isoCode: 'fa', countryCode: 'IR' },
  { label: 'Polish', isoCode: 'pl', countryCode: 'PL' },
  { label: 'Portuguese', isoCode: 'pt', countryCode: 'PT' },
  { label: 'Portuguese (Brazil)', isoCode: 'pt-BR', countryCode: 'BR' },
  { label: 'Portuguese (Portugal)', isoCode: 'pt-PT', countryCode: 'PT' },
  { label: 'Punjabi', isoCode: 'pa', countryCode: 'IN' },
  { label: 'Punjabi (Shahmukhi)', isoCode: 'pa-Arab', countryCode: 'PK' },
  { label: 'Quechua', isoCode: 'qu', countryCode: 'PE' },
  { label: 'Romani', isoCode: 'rom', countryCode: 'RO' },
  { label: 'Romanian', isoCode: 'ro', countryCode: 'RO' },
  { label: 'Rundi', isoCode: 'rn', countryCode: 'BI' },
  { label: 'Russian', isoCode: 'ru', countryCode: 'RU' },
  { label: 'Samoan', isoCode: 'sm', countryCode: 'WS' },
  { label: 'Sango', isoCode: 'sg', countryCode: 'CF' },
  { label: 'Sanskrit', isoCode: 'sa', countryCode: 'IN' },
  { label: 'Scots Gaelic', isoCode: 'gd', countryCode: 'GB' },
  { label: 'Sepedi', isoCode: 'nso', countryCode: 'ZA' },
  { label: 'Serbian', isoCode: 'sr', countryCode: 'RS' },
  { label: 'Sesotho', isoCode: 'st', countryCode: 'LS' },
  { label: 'Seychellois Creole', isoCode: 'crs', countryCode: 'SC' },
  { label: 'Shan', isoCode: 'shn', countryCode: 'MM' },
  { label: 'Shona', isoCode: 'sn', countryCode: 'ZW' },
  { label: 'Sicilian', isoCode: 'scn', countryCode: 'IT' },
  { label: 'Silesian', isoCode: 'szl', countryCode: 'PL' },
  { label: 'Sindhi', isoCode: 'sd', countryCode: 'PK' },
  { label: 'Sinhala (Sinhalese)', isoCode: 'si', countryCode: 'LK' },
  { label: 'Slovak', isoCode: 'sk', countryCode: 'SK' },
  { label: 'Slovenian', isoCode: 'sl', countryCode: 'SI' },
  { label: 'Somali', isoCode: 'so', countryCode: 'SO' },
  { label: 'Spanish', isoCode: 'es', countryCode: 'ES' },
  { label: 'Sundanese', isoCode: 'su', countryCode: 'ID' },
  { label: 'Swahili', isoCode: 'sw', countryCode: 'TZ' },
  { label: 'Swati', isoCode: 'ss', countryCode: 'SZ' },
  { label: 'Swedish', isoCode: 'sv', countryCode: 'SE' },
  { label: 'Tajik', isoCode: 'tg', countryCode: 'TJ' },
  { label: 'Tamil', isoCode: 'ta', countryCode: 'IN' },
  { label: 'Tatar', isoCode: 'tt', countryCode: 'RU' },
  { label: 'Telugu', isoCode: 'te', countryCode: 'IN' },
  { label: 'Tetum', isoCode: 'tet', countryCode: 'TL' },
  { label: 'Thai', isoCode: 'th', countryCode: 'TH' },
  { label: 'Tigrinya', isoCode: 'ti', countryCode: 'ER' },
  { label: 'Tsonga', isoCode: 'ts', countryCode: 'ZA' },
  { label: 'Tswana', isoCode: 'tn', countryCode: 'BW' },
  { label: 'Turkish', isoCode: 'tr', countryCode: 'TR' },
  { label: 'Turkmen', isoCode: 'tk', countryCode: 'TM' },
  { label: 'Twi (Akan)', isoCode: 'ak', countryCode: 'GH' },
  { label: 'Ukrainian', isoCode: 'uk', countryCode: 'UA' },
  { label: 'Urdu', isoCode: 'ur', countryCode: 'PK' },
  { label: 'Uyghur', isoCode: 'ug', countryCode: 'CN' },
  { label: 'Uzbek', isoCode: 'uz', countryCode: 'UZ' },
  { label: 'Vietnamese', isoCode: 'vi', countryCode: 'VN' },
  { label: 'Welsh', isoCode: 'cy', countryCode: 'GB' },
  { label: 'Xhosa', isoCode: 'xh', countryCode: 'ZA' },
  { label: 'Yiddish', isoCode: 'yi', countryCode: 'IL' },
  { label: 'Yoruba', isoCode: 'yo', countryCode: 'NG' },
  { label: 'Yucatec Maya', isoCode: 'yua', countryCode: 'MX' },
  { label: 'Zulu', isoCode: 'zu', countryCode: 'ZA' }
];

export const awsLanguages: ILanugage[] = [
  { label: 'Afrikaans', isoCode: 'af', countryCode: 'ZA' },
  { label: 'Albanian', isoCode: 'sq', countryCode: 'AL' },
  { label: 'Amharic', isoCode: 'am', countryCode: 'ET' },
  { label: 'Arabic', isoCode: 'ar', countryCode: 'SA' },
  { label: 'Armenian', isoCode: 'hy', countryCode: 'AM' },
  { label: 'Azerbaijani', isoCode: 'az', countryCode: 'AZ' },
  { label: 'Bengali', isoCode: 'bn', countryCode: 'BD' },
  { label: 'Bosnian', isoCode: 'bs', countryCode: 'BA' },
  { label: 'Bulgarian', isoCode: 'bg', countryCode: 'BG' },
  { label: 'Catalan', isoCode: 'ca', countryCode: 'ES' },
  { label: 'Chinese (Simplified)', isoCode: 'zh', countryCode: 'CN' },
  { label: 'Chinese (Traditional)', isoCode: 'zh-TW', countryCode: 'TW' },
  { label: 'Croatian', isoCode: 'hr', countryCode: 'HR' },
  { label: 'Czech', isoCode: 'cs', countryCode: 'CZ' },
  { label: 'Danish', isoCode: 'da', countryCode: 'DK' },
  { label: 'Dari', isoCode: 'fa-AF', countryCode: 'AF' },
  { label: 'Dutch', isoCode: 'nl', countryCode: 'NL' },
  { label: 'English', isoCode: 'en', countryCode: 'US' },
  { label: 'Estonian', isoCode: 'et', countryCode: 'EE' },
  { label: 'Farsi (Persian)', isoCode: 'fa', countryCode: 'IR' },
  { label: 'Filipino, Tagalog', isoCode: 'tl', countryCode: 'PH' },
  { label: 'Finnish', isoCode: 'fi', countryCode: 'FI' },
  { label: 'French', isoCode: 'fr', countryCode: 'FR' },
  { label: 'French (Canada)', isoCode: 'fr-CA', countryCode: 'CA' },
  { label: 'Georgian', isoCode: 'ka', countryCode: 'GE' },
  { label: 'German', isoCode: 'de', countryCode: 'DE' },
  { label: 'Greek', isoCode: 'el', countryCode: 'GR' },
  { label: 'Gujarati', isoCode: 'gu', countryCode: 'IN' },
  { label: 'Haitian Creole', isoCode: 'ht', countryCode: 'HT' },
  { label: 'Hausa', isoCode: 'ha', countryCode: 'NG' },
  { label: 'Hebrew', isoCode: 'he', countryCode: 'IL' },
  { label: 'Hindi', isoCode: 'hi', countryCode: 'IN' },
  { label: 'Hungarian', isoCode: 'hu', countryCode: 'HU' },
  { label: 'Icelandic', isoCode: 'is', countryCode: 'IS' },
  { label: 'Indonesian', isoCode: 'id', countryCode: 'ID' },
  { label: 'Irish', isoCode: 'ga', countryCode: 'IE' },
  { label: 'Italian', isoCode: 'it', countryCode: 'IT' },
  { label: 'Japanese', isoCode: 'ja', countryCode: 'JP' },
  { label: 'Kannada', isoCode: 'kn', countryCode: 'IN' },
  { label: 'Kazakh', isoCode: 'kk', countryCode: 'KZ' },
  { label: 'Korean', isoCode: 'ko', countryCode: 'KR' },
  { label: 'Latvian', isoCode: 'lv', countryCode: 'LV' },
  { label: 'Lithuanian', isoCode: 'lt', countryCode: 'LT' },
  { label: 'Macedonian', isoCode: 'mk', countryCode: 'MK' },
  { label: 'Malay', isoCode: 'ms', countryCode: 'MY' },
  { label: 'Malayalam', isoCode: 'ml', countryCode: 'IN' },
  { label: 'Maltese', isoCode: 'mt', countryCode: 'MT' },
  { label: 'Marathi', isoCode: 'mr', countryCode: 'IN' },
  { label: 'Mongolian', isoCode: 'mn', countryCode: 'MN' },
  { label: 'Norwegian (Bokmål)', isoCode: 'no', countryCode: 'NO' },
  { label: 'Pashto', isoCode: 'ps', countryCode: 'AF' },
  { label: 'Polish', isoCode: 'pl', countryCode: 'PL' },
  { label: 'Portuguese (Brazil)', isoCode: 'pt', countryCode: 'BR' },
  { label: 'Portuguese (Portugal)', isoCode: 'pt-PT', countryCode: 'PT' },
  { label: 'Punjabi', isoCode: 'pa', countryCode: 'IN' },
  { label: 'Romanian', isoCode: 'ro', countryCode: 'RO' },
  { label: 'Russian', isoCode: 'ru', countryCode: 'RU' },
  { label: 'Serbian', isoCode: 'sr', countryCode: 'RS' },
  { label: 'Sinhala', isoCode: 'si', countryCode: 'LK' },
  { label: 'Slovak', isoCode: 'sk', countryCode: 'SK' },
  { label: 'Slovenian', isoCode: 'sl', countryCode: 'SI' },
  { label: 'Somali', isoCode: 'so', countryCode: 'SO' },
  { label: 'Spanish', isoCode: 'es', countryCode: 'ES' },
  { label: 'Spanish (Mexico)', isoCode: 'es-MX', countryCode: 'MX' },
  { label: 'Swahili', isoCode: 'sw', countryCode: 'TZ' },
  { label: 'Swedish', isoCode: 'sv', countryCode: 'SE' },
  { label: 'Tamil', isoCode: 'ta', countryCode: 'IN' },
  { label: 'Telugu', isoCode: 'te', countryCode: 'IN' },
  { label: 'Thai', isoCode: 'th', countryCode: 'TH' },
  { label: 'Turkish', isoCode: 'tr', countryCode: 'TR' },
  { label: 'Ukrainian', isoCode: 'uk', countryCode: 'UA' },
  { label: 'Urdu', isoCode: 'ur', countryCode: 'PK' },
  { label: 'Uzbek', isoCode: 'uz', countryCode: 'UZ' },
  { label: 'Vietnamese', isoCode: 'vi', countryCode: 'VN' },
  { label: 'Welsh', isoCode: 'cy', countryCode: 'GB' }
];

export const salesForceLanguages: ILanugage[] = [
  { 'label': 'Afrikaans', 'countryCode': 'ZA', 'isoCode': 'af' },
  { 'label': 'Albanian', 'countryCode': 'AL', 'isoCode': 'sq' },
  { 'label': 'Amharic', 'countryCode': 'ET', 'isoCode': 'am' },
  { 'label': 'Arabic (Algeria)', 'countryCode': 'DZ', 'isoCode': 'ar_DZ' },
  { 'label': 'Arabic (Bahrain)', 'countryCode': 'BH', 'isoCode': 'ar_BH' },
  { 'label': 'Arabic (Egypt)', 'countryCode': 'EG', 'isoCode': 'ar_EG' },
  { 'label': 'Arabic (Iraq)', 'countryCode': 'IQ', 'isoCode': 'ar_IQ' },
  { 'label': 'Arabic (Jordan)', 'countryCode': 'JO', 'isoCode': 'ar_JO' },
  { 'label': 'Arabic (Kuwait)', 'countryCode': 'KW', 'isoCode': 'ar_KW' },
  { 'label': 'Arabic (Lebanon)', 'countryCode': 'LB', 'isoCode': 'ar_LB' },
  { 'label': 'Arabic (Libya)', 'countryCode': 'LY', 'isoCode': 'ar_LY' },
  { 'label': 'Arabic (Morocco)', 'countryCode': 'MA', 'isoCode': 'ar_MA' },
  { 'label': 'Arabic (Oman)', 'countryCode': 'OM', 'isoCode': 'ar_OM' },
  { 'label': 'Arabic (Qatar)', 'countryCode': 'QA', 'isoCode': 'ar_QA' },
  { 'label': 'Arabic (Saudi Arabia)', 'countryCode': 'SA', 'isoCode': 'ar_SA' },
  { 'label': 'Arabic (Sudan)', 'countryCode': 'SD', 'isoCode': 'ar_SD' },
  { 'label': 'Arabic (Syria)', 'countryCode': 'SY', 'isoCode': 'ar_SY' },
  { 'label': 'Arabic (Tunisia)', 'countryCode': 'TN', 'isoCode': 'ar_TN' },
  { 'label': 'Arabic (United Arab Emirates)', 'countryCode': 'AE', 'isoCode': 'ar_AE' },
  { 'label': 'Arabic (Yemen)', 'countryCode': 'YE', 'isoCode': 'ar_YE' },
  { 'label': 'Basque', 'countryCode': 'ES', 'isoCode': 'eu' },
  { 'label': 'Bengali', 'countryCode': 'BD', 'isoCode': 'bn' },
  { 'label': 'Bosnian', 'countryCode': 'BA', 'isoCode': 'bs' },
  { 'label': 'Bulgarian', 'countryCode': 'BG', 'isoCode': 'bg' },
  { 'label': 'Catalan', 'countryCode': 'ES', 'isoCode': 'ca' },
  { 'label': 'Chinese (Simplified)', 'countryCode': 'CN', 'isoCode': 'zh_CN' },
  { 'label': 'Chinese (Traditional)', 'countryCode': 'TW', 'isoCode': 'zh_TW' },
  { 'label': 'Croatian', 'countryCode': 'HR', 'isoCode': 'hr' },
  { 'label': 'Czech', 'countryCode': 'CZ', 'isoCode': 'cs' },
  { 'label': 'Danish', 'countryCode': 'DK', 'isoCode': 'da' },
  { 'label': 'Dutch', 'countryCode': 'NL', 'isoCode': 'nl' },
  { 'label': 'English', 'countryCode': 'US', 'isoCode': 'en_US' },
  { 'label': 'Finnish', 'countryCode': 'FI', 'isoCode': 'fi' },
  { 'label': 'French', 'countryCode': 'FR', 'isoCode': 'fr' },
  { 'label': 'German', 'countryCode': 'DE', 'isoCode': 'de' },
  { 'label': 'Greek', 'countryCode': 'GR', 'isoCode': 'el' },
  { 'label': 'Hebrew', 'countryCode': 'IL', 'isoCode': 'he' },
  { 'label': 'Hindi', 'countryCode': 'IN', 'isoCode': 'hi' },
  { 'label': 'Hungarian', 'countryCode': 'HU', 'isoCode': 'hu' },
  { 'label': 'Indonesian', 'countryCode': 'ID', 'isoCode': 'id' },
  { 'label': 'Italian', 'countryCode': 'IT', 'isoCode': 'it' },
  { 'label': 'Japanese', 'countryCode': 'JP', 'isoCode': 'ja' },
  { 'label': 'Korean', 'countryCode': 'KR', 'isoCode': 'ko' },
  { 'label': 'Latvian', 'countryCode': 'LV', 'isoCode': 'lv' },
  { 'label': 'Lithuanian', 'countryCode': 'LT', 'isoCode': 'lt' },
  { 'label': 'Macedonian', 'countryCode': 'MK', 'isoCode': 'mk' },
  { 'label': 'Malay', 'countryCode': 'MY', 'isoCode': 'ms' },
  { 'label': 'Malayalam', 'countryCode': 'IN', 'isoCode': 'ml' },
  { 'label': 'Norwegian', 'countryCode': 'NO', 'isoCode': 'no' },
  { 'label': 'Persian', 'countryCode': 'IR', 'isoCode': 'fa' },
  { 'label': 'Polish', 'countryCode': 'PL', 'isoCode': 'pl' },
  { 'label': 'Portuguese', 'countryCode': 'PT', 'isoCode': 'pt_PT' },
  { 'label': 'Romanian', 'countryCode': 'RO', 'isoCode': 'ro' },
  { 'label': 'Russian', 'countryCode': 'RU', 'isoCode': 'ru' },
  { 'label': 'Serbian', 'countryCode': 'RS', 'isoCode': 'sr' },
  { 'label': 'Slovak', 'countryCode': 'SK', 'isoCode': 'sk' },
  { 'label': 'Slovenian', 'countryCode': 'SI', 'isoCode': 'sl' },
  { 'label': 'Spanish', 'countryCode': 'ES', 'isoCode': 'es' },
  { 'label': 'Swahili', 'countryCode': 'KE', 'isoCode': 'sw' },
  { 'label': 'Swedish', 'countryCode': 'SE', 'isoCode': 'sv' },
  { 'label': 'Thai', 'countryCode': 'TH', 'isoCode': 'th' },
  { 'label': 'Turkish', 'countryCode': 'TR', 'isoCode': 'tr' },
  { 'label': 'Ukrainian', 'countryCode': 'UA', 'isoCode': 'uk' },
  { 'label': 'Vietnamese', 'countryCode': 'VN', 'isoCode': 'vi' },
  { 'label': 'Zulu', 'countryCode': 'ZA', 'isoCode': 'zu' }
]