import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
// types
import { IOrgCredential } from 'src/types/orgCredential';
// assets
// components
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import useFetcher, { endpoints } from 'src/utils/axios';
import { useDashboardContext } from 'src/context/hooks';
import { Alert, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  credential?: IOrgCredential;
  wizzardSetupType?: string;
  onWizzardUpdate?: (status: string) => void;
};

export default function CredentialsQuickCreateForm({
  credential,
  open,
  onClose,
  wizzardSetupType,
  onWizzardUpdate,
}: Props) {
  const dashBoardContext = useDashboardContext();
  const [organizationType, setOrganizationType] = useState<any>('production');
  const { updateData, fetchData } = useFetcher();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [newWizzardStatus, setNewWizzardStatus] = useState(wizzardSetupType);
  const theme = useTheme();
  const NewUserSchema = Yup.object().shape({
    org_crediantial_name: Yup.string().required('Org Name is required'),
    api_name: Yup.string()
      .matches(/^[a-zA-Z0-9_]+$/, {
        message: 'String must contain only letters, numbers, and underscores',
      })
      .required('This Value is required'),
    custom_domain:
      organizationType === 'custom_domain'
        ? Yup.string().required('Custom Domain is required')
        : Yup.string().notRequired(),
  });

  const defaultValues = useMemo(
    () => ({
      org_crediantial_id: credential?.credentials_id || '',
      org_crediantial_name: credential?.org_crediantial_name || '',
      instance_url: credential?.instance_url || '',
      status: credential?.status || '',
      api_name: credential?.api_name || '',
      custom_domain: '',
    }),
    [credential]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const handleUpdateWizzard = () => {
    if (dashBoardContext.currentOrg && newWizzardStatus === 'credentialSetup') {
      const setupWizzardStatus = 'credSetupFinished';
      const updatedOrg = { ...dashBoardContext.currentOrg, setupWizzardStatus };
      dashBoardContext.setCurrentOrgs((prevOrg) => (prevOrg ? updatedOrg : undefined));
      updateData(endpoints.org.put, updatedOrg);
      setNewWizzardStatus(setupWizzardStatus);
      if (onWizzardUpdate) {
        onWizzardUpdate(setupWizzardStatus);
      }
    }
  };

  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      getAutherationUrl(data.org_crediantial_name, data.api_name, data.custom_domain ?? undefined);
      await new Promise((resolve) => setTimeout(resolve, 500)).then(() => handleUpdateWizzard());
      reset();
    } catch (error) {
      console.error(error);
    }
  });
  const createApiName = () => {
    const input = watch().org_crediantial_name;
    const transformedString = input.replace(/[^\w\s]+/g, '').replace(/\s+/g, '_');
    setValue('api_name', transformedString);
  };

  const getAutherationUrl = (org_crediantial_name: string, api_name: string | undefined, custom_domain: string | undefined) => {
    if (dashBoardContext.currentOrg) {
      fetchData([
        endpoints.credential.authorize,
        {
          params: {
            org_credential_id: org_crediantial_name,
            api_name,
            type: organizationType,
            redirect: "credential",
            custom_domain,
          },
        },
      ])
        .then((response) => {
          if (/^(ftp|http|https):\/\/[^ "]+$/.test(response)) {
            window.open(response, '_blank');
            window.close();
            onClose();
          } else {
            setErrorMessage(response);
          }
        })
        .catch((response) => {
          setErrorMessage(response);
        });
    }
  };
  useEffect(() => {
    const time = setTimeout(() => {
      createApiName();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch().org_crediantial_name]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Create Credential</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
            sx={{ p: 2 }}
          >
            <RHFTextField name="org_crediantial_name" label="Credential Name" />
            <RHFTextField name="api_name" label="API Name" />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Type"
                value={organizationType}
                onChange={(e) => setOrganizationType(e.target.value as string)}
              >
                <MenuItem value="production">Production</MenuItem>
                <MenuItem value="sandbox">Sandbox</MenuItem>
                <MenuItem value="custom_domain">Custom Domain</MenuItem>
              </Select>
            </FormControl>
            {organizationType === 'custom_domain' && 
              <RHFTextField name="custom_domain" label="Custom Domain" />
            }
          </Box>
          {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <></>}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Tooltip
            title={
              <div style={{ padding: 4 }}>
                <ul
                  style={{ lineHeight: '1.8', margin: 0, paddingLeft: '1.2em', fontSize: '0.8rem' }}
                >
                  <li>Click to start creating your Credential.</li>
                </ul>
              </div>
            }
            open={newWizzardStatus === 'credentialSetup' || false}
            arrow
            placement="top"
          >
            <LoadingButton type="submit" variant="contained" color="primary" loading={isSubmitting}>
              Create
            </LoadingButton>
          </Tooltip>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
